.root {
  @apply px-2 py-6 mx-auto mt-4 flex max-w-[634px] flex-col items-center justify-center gap-y-4 text-center;
  @apply md:mt-[150px];
  @apply lg:mt-[180px];
}
.errorTitle {
  @apply h2 sm:h1;
}
.errorCopy {
  @apply pb-4 sm:text-lg;
}